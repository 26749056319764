// @font-face {
//     font-family: 'DIN-Regular';
//     src: url(../../assets/fonts/DIN/DIN-Regular.otf)
// }

@header-height:60px;

body {
    color: black;
    background-color: white;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    .body-wrapper {
        box-sizing: content-box;
        width: 100%;
        height: 100%;

        .header-wrapper {
            box-sizing: border-box;
            position: fixed;
            height: @header-height;
            left: 0;
            right: 0;
            top: 0;
            background-color: white;

            .header-container {
                box-sizing: content-box;
                color: white;
                line-height: @header-height;
                margin: 0px 15px 0px 0px;

                .logo-wrapper {
                    display: inline-block;
                    font-family: DIN-Regular, Helvetica;
                    vertical-align: top;
                    background-color: #FFCC00;
                    border-radius: 0px 0px 40px 0px;
                    box-shadow: 1px 1px 10px 0px #00000030;

                    .nav-link {
                        padding: 0px 30px 0px 20px;
                        display: inline-block;
                        text-decoration: none;

                        &:hover {
                            text-decoration: none;
                        }

                        img {
                            height: @header-height;
                        }

                        span {
                            vertical-align: top;
                            color: black;
                            font-weight: bold;
                        }
                    }
                }

                .nav-wrapper {
                    display: inline-block;
                    margin-left:40px;
                    vertical-align: top;

                    .nav-container{
                        .nav-list {
                            list-style-type: none;
                            margin: 0;
                            padding: 0;
                            .nav-list-item {
                                display: inline-block;
                                .nav-link {
                                    padding: 0 10px;
                                    display: inline-block;
                                    cursor: pointer;
                                    text-decoration: none;
                                    color:white;

                                    &.selected, &:hover {
                                        background-color:#e6b800;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-wrapper {
            margin-top: @header-height;
            .main-container {
                margin: 0 auto 0;
                max-width: 600px;
            }
        }
    }
}